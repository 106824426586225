<template>
  <keep-alive>
    <router-view class="router-view" />
  </keep-alive>
</template>

<script>
// @ is an alias to /src
export default {
  name: "App",
  components: {
    // Home
  },
};
</script>

<style lang="scss">
@import "./assets/css/base.scss";
// @import "./assets/font/RobotoFlex/robotoFlex.css";
#app {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  position: relative;
  min-height: 100%;
  height: auto !important;
  height: 100%; /*IE6不识别min-height*/
  position: relative;
}
:root{
  --c-pro:#84561D;
  --c-black-filter: rgba(0, 0, 0, 0.5);
  --c-black-filter2: rgba(0, 0, 0, 0.03);
  --c-black-filter3: rgba(151, 151, 151, 0.14);
  --c-black-filter4: rgba(150, 150, 150, 0.2);
  --c-black-filter5: rgba(0, 0, 0, 0.06);
  --c-black-filter5: rgba(0, 0, 0, 0.08);
  --c-back: #f6f6f6;
  --c-header-bottom: #DEDEDE;
  --c-black: #000000;
  --c-line: #D8D8D8;
  --c-red: #D91D06;
  --c-red-link: #d91d06;
  --c-white: #ffffff;
  --c-blue: #0091ff;
  --c-bg: var(--c-white);
  --c-homebg: #fff;
  --shadow-3: 0 12px 32px rgba(0, 0, 0, .1), 0 2px 6px rgba(0, 0, 0, .08);
}
@media (prefers-color-scheme: dark) {
  :root{
    --c-black-filter: rgba(255, 255, 255, 0.5);
    --c-black-filter2: rgba(255, 255, 255, 0.03);
    --c-black-filter3: rgba(151, 151, 151, 0.14);
    --c-black-filter4: rgba(150, 150, 150, 0.2);
    --c-black-filter5: rgba(255, 255, 255, 0.06);
    --c-black-filter5: rgba(255, 255, 255, 0.08);
    --c-header-bottom: #282828;
    --c-back: #131313;
    --c-homebg: var(--c-back);
    --c-black: #ffffff;
    --c-line: #292929;
    --c-white: #000;
    --c-bg: var(--c-white);
    --shadow-3: 0 12px 32px rgba(100, 100, 100, .1), 0 2px 6px rgba(100, 100, 100, .08);
  }
}
</style>
