/**
 * @version: v 1.0.0
 * @Github: https://github.com/fastclip
 * @Author: fastclip
 * @CreateDate: 2022-01-04 22:08:12
 * @LastEditors: fastclip
 * @LastEditTime: 2021-01-05 23:32:14
 * @FilePath: /fast-clip/router/modules/home.js
 **/
export default [
  {
    path: "/",
    name: "index",
    meta: {
      index: 1,
    },
    component: () => import("@/views/home/home.vue"),
  },
];
