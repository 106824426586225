import { createRouter, createWebHistory } from "vue-router";

const indexRouter = {
  path: "/",
  component: () => import("../views/index.vue"),
  redirect: "/index",
  children: [],
};

const routes = [
  indexRouter,
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/*",
    name: "404",
    meta: {
      index: 1,
    },
    component: () => import("@/views/error/404"),
  },
];

const routerContext = require.context("./modules", true, /\.js$/);
routerContext.keys().forEach((route) => {
  const routerModule = routerContext(route);
  indexRouter.children = [
    ...indexRouter.children,
    ...(routerModule.default || routerModule),
  ];
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
