import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 国际化组件
import i18n from "@/locales/setupI18n";

const app = createApp(App);
app.use(store).use(i18n).use(router).mount("#app");
app.config.globalProperties.testApp = "testGlobal";
app.config.globalProperties.globalClick = (callback) => {
  document.body.addEventListener("click", (event)=>{
    callback();
  })
}
