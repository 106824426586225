export default {
  en: {
    key: "en",
    languages: [{ name: "English", lan: "en" }, { name: "简体中文", lan:"zh"},{ name: "日本語", lan:"ja"},{ name: "한국어", lan:"ko"},{name: "العربية", lan:"ar"},{ name: "Deutsch", lan:"de"},{ name: "français", lan:"fr"},{ name: "русский", lan:"ru"}],
    header: {
      story: "Story",
      language: "Languages/en",
      kit: "PressKit",
      storyHref:"https://fastclip.zhubai.love/",
      kitHref:"https://fastclip.app/presskit/PressKit.zip"
    },
    content: {
      title: "A new way to treat snippet",
      desc: "Collect the snippet, then parse, paste and share it.",
      appStore: "FastClip 3 on the App Store",
      scoreStar: "5",
      score: "4.8",
      openLink: "Open link",
      members: "2155 members",
      group: "Join our telegram group",
      appDesc: {
        name: "Get the snippet through the following ways. System {clipboard}, {keyboard} extension{pro}, {share} extension, {safari} extension.",
        clipboard: "Clipboard",
        keyboard: "Keyboard",
        pro:"[Pro]",
        share:"Share",
        safari: "Safari"
      },
      appNote:{
        name: 'Sync the snippet through the following ways. {icloud}{pro}, {network}.',
        icloud: "iCloud",
        pro: "[Pro]",
        network: "Sync network"
      }
    },
    pricing: {
      title: "Pricing",
      options: [
        {package: "Monthly", price:"$0.49"},
        { package: "Yearly", price: "$2.99" },
        { package: "Lifetime", price: "$8.99" }
      ]
    },
    features: {
      title: "Features",
      details: [
        { type: "header", item: "Item(s)", free:"Free", pro:"Pro"},
        { type: "text", item: "Snippets", free: "1000", pro: "Unlimited", border:"bottom" },
        { type: "image", item: "Keyboard extension(ios)", free: "0", pro: "1", border: "bottom" },
        { type: "image", item: "Safari extension(ios)", free: "1", pro: "1", border: "bottom" },
        { type: "image", item: "iCloud sync", free: "0", pro: "1", border: "none" },
        { type: "image", item: "File manage", free: "1", pro: "1", border: "none"  },
        { type: "image", item: "Sync network", free: "1", pro: "1", border: "none"  },
        { type: "image", item: "Handoff", free: "1", pro: "1", border: "none"  },
        { type: "image", item: "Cards", free: "1", pro: "1", border: "none" },
      ]
    }
  },
  zh: {
    key: "zh",
    languages: [{ name: "English", lan: "en" }, { name: "简体中文", lan:"zh"},{ name: "日本語", lan:"ja"},{ name: "한국어", lan:"ko"},{name: "العربية", lan:"ar"},{ name: "Deutsch", lan:"de"},{ name: "français", lan:"fr"},{ name: "русский", lan:"ru"}],
    header: {
      story: "故事",
      language: "简体中文/zh",
      kit: "媒体资料",
      storyHref:"https://fastclip.zhubai.love/",
      kitHref:"https://fastclip.app/presskit/PressKit.zip"
    },
    content: {
      title: "用全新的方式处理片段",
      desc: "收集、解析、粘贴和分享",
      appStore: "FastClip 3 苹果商店版本",
      scoreStar: "5",
      score: "4.8",
      openLink: "打开链接",
      members: "2155个成员",
      group: "加入我们的电报组",
      appDesc: {
        name: "你通过以下方式收集片段, 系统{clipboard}、{keyboard}扩展{pro}, {share}扩展, {safari}扩展。",
        clipboard: "剪贴板",
        keyboard: "键盘",
        pro: "[Pro]",
        share: "分享",
        safari: "Safari"
      },
      appNote:{
        name: '你通过以下方式同步片段, {icloud}{pro},{network}。',
        icloud: "iCloud",
        pro: "[Pro]",
        network: "同步网络"
      }
    },
    pricing:{
      title:"价格",
      options: [
        { package: "按月订阅", price: "¥1.99" },
        { package: "按年订阅", price: "¥18.00" },
        { package: "永久", price: "¥60.00" }
      ]
    },
    features: {
      title: "功能",
      details: [
        { type: "header", item: "项", free: "免费", pro: "Pro" },
        { type: "text", item: "Snippets", free: "1000", pro: "无限制", border: "bottom" },
        { type: "image", item: "Keyboard extension(ios)", free: "0", pro: "1", border: "bottom" },
        { type: "image", item: "Safari extension(ios)", free: "1", pro: "1", border: "bottom" },
        { type: "image", item: "iCloud sync", free: "0", pro: "1", border: "none" },
        { type: "image", item: "File manage", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Sync network", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Handoff", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Cards", free: "1", pro: "1", border: "none" },
      ]
    }
  },
  ja: {
    key: "ja",
    languages: [{ name: "English", lan: "en" }, { name: "简体中文", lan:"zh"},{ name: "日本語", lan:"ja"},{ name: "한국어", lan:"ko"},{name: "العربية", lan:"ar"},{ name: "Deutsch", lan:"de"},{ name: "français", lan:"fr"},{ name: "русский", lan:"ru"}],
    header: {
      story: "ストーリー",
      language: "日本語/ja",
      kit: "プレスキット",
      storyHref:"https://fastclip.zhubai.love/",
      kitHref:"https://fastclip.app/presskit/PressKit.zip"
    },
    content: {
      title: "新しい方法でスニペットを処理する",
      desc: "スニペットの収集、解析、貼り付けと共有",
      appStore: "FastClip 3 on the App Store",
      scoreStar: "5",
      score: "4.8",
      openLink: "ジャンプリンク",
      members: "2155 members",
      group: "Telegramに加入する",
      appDesc: {
        name: "システムの{clipboard}、{keyboard}拡張{pro}、{share}拡張、{safari}拡張を通してスニペッ。",
        clipboard: "クリップボード",
        keyboard: "キーボード",
        pro: "[Pro]",
        share: "共有",
        safari: "Safari"
      },
      appNote:{
        name: '{icloud}{pro}、{network}を通してスニペットを同期化することができます。',
        icloud: "iCloud",
        pro: "[Pro]",
        network: "Sync network"
      }
    },
    pricing:{
      title:"価格設定",
      options: [
        { package: "月次購読", price: "¥50" },
        { package: "年次購読", price: "¥350" },
        { package: "永久", price: "¥1,100" }
      ]
    },
    features: {
      title: "機能",
      details: [
        { type: "header", item: "項目", free: "Free", pro: "Pro" },
        { type: "text", item: "Snippets", free: "1000", pro: "Unlimited", border: "bottom" },
        { type: "image", item: "Keyboard extension(ios)", free: "0", pro: "1", border: "bottom" },
        { type: "image", item: "Safari extension(ios)", free: "1", pro: "1", border: "bottom" },
        { type: "image", item: "iCloud sync", free: "0", pro: "1", border: "none" },
        { type: "image", item: "File manage", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Sync network", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Handoff", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Cards", free: "1", pro: "1", border: "none" },
      ]
    }
  },
  ko: {
    key: "ko",
    languages: [{ name: "English", lan: "en" }, { name: "简体中文", lan:"zh"},{ name: "日本語", lan:"ja"},{ name: "한국어", lan:"ko"},{name: "العربية", lan:"ar"},{ name: "Deutsch", lan:"de"},{ name: "français", lan:"fr"},{ name: "русский", lan:"ru"}],
    header: {
      story: "이야기",
      language: "한국어/ko",
      kit: "미디어 데이터",
      storyHref:"https://fastclip.zhubai.love/",
      kitHref:"https://fastclip.app/presskit/PressKit.zip"
    },
    content: {
      title: "새로운 방식으로 스니펫 처리하기",
      desc: "스니펫 수집, 분석, 복사 또는 공유 가능",
      appStore: "FastClip 3 on the App Store",
      scoreStar: "5",
      score: "4.8",
      openLink: "건너뛰기 링크",
      members: "2155 members",
      group: "Telegram 가입",
      appDesc: {
        name: "시스템의 클립보드, 키보드 확장, 공유 확장과 브라우저 확장으로 스니펫。",
        clipboard: "クリップボード",
        keyboard: "キーボード",
        pro: "[Pro]",
        share: "共有",
        safari: "Safari"
      },
      appNote:{
        name: 'Sync network 또는 iCloud를 통해 스니펫 동기화가 가능합니다。',
        icloud: "iCloud",
        pro: "[Pro]",
        network: "Sync network"
      }
    },
    pricing:{
      title:"가격",
      options: [
        { package: "월간 구독", price: "￦500" },
        { package: "연간 구독", price: "￦3,900" },
        { package: "영구", price: "￦11,000" }
      ]
    },
    features: {
      title: "기능",
      details: [
        { type: "header", item: "항", free: "Free", pro: "Pro" },
        { type: "text", item: "Snippets", free: "1000", pro: "Unlimited", border: "bottom" },
        { type: "image", item: "Keyboard extension(ios)", free: "0", pro: "1", border: "bottom" },
        { type: "image", item: "Safari extension(ios)", free: "1", pro: "1", border: "bottom" },
        { type: "image", item: "iCloud sync", free: "0", pro: "1", border: "none" },
        { type: "image", item: "File manage", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Sync network", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Handoff", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Cards", free: "1", pro: "1", border: "none" },
      ]
    }
  },
  ar: {
    key: "ar",
    languages: [{ name: "English", lan: "en" }, { name: "简体中文", lan:"zh"},{ name: "日本語", lan:"ja"},{ name: "한국어", lan:"ko"},{name: "العربية", lan:"ar"},{ name: "Deutsch", lan:"de"},{ name: "français", lan:"fr"},{ name: "русский", lan:"ru"}],
    header: {
      story: "قصة",
      language: "العربية/ar",
      kit: "واد إعلامية",
      storyHref:"https://fastclip.zhubai.love/",
      kitHref:"https://fastclip.app/presskit/PressKit.zip"
    },
    content: {
      title: "الجة المقتطفات بطريقة جديدة تماما",
      desc: "اجمع المقتطفات وقم بتحليلها ولصقها ومشاركتها",
      appStore: "FastClip 3 on the App Store",
      scoreStar: "5",
      score: "4.8",
      openLink: "رابط الإنتقال السريع",
      members: "2155 members",
      group: "انضم إلى Telegram",
      appDesc: {
        name: "مكنك الحصول على المقتطفات من خلال حافظة النظام وملحقات لوحة المفاتيح وامتداد المشاركة وامتداد المتصف",
        clipboard: "クリップボード",
        keyboard: "キーボード",
        pro: "[Pro]",
        share: "共有",
        safari: "Safari"
      },
      appNote:{
        name: 'يمكنك مزامنة المقتطفات عبر Sync network وiCloud',
        icloud: "iCloud",
        pro: "[Pro]",
        network: "Sync network"
      }
    },
    pricing:{
      title:"التسعير",
      options: [
        { package: "شتراك شهري", price: "$0.49" },
        { package: "شتراك سنوي", price: "$2.99" },
        { package: "ائم", price: "$8.99" }
      ]
    },
    features: {
      title: "وظائف",
      details: [
        { type: "header", item: "بند", free: "Free", pro: "Pro" },
        { type: "text", item: "Snippets", free: "1000", pro: "Unlimited", border: "bottom" },
        { type: "image", item: "Keyboard extension(ios)", free: "0", pro: "1", border: "bottom" },
        { type: "image", item: "Safari extension(ios)", free: "1", pro: "1", border: "bottom" },
        { type: "image", item: "iCloud sync", free: "0", pro: "1", border: "none" },
        { type: "image", item: "File manage", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Sync network", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Handoff", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Cards", free: "1", pro: "1", border: "none" },
      ]
    }
  },
  de: {
    key: "de",
    languages: [{ name: "English", lan: "en" }, { name: "简体中文", lan:"zh"},{ name: "日本語", lan:"ja"},{ name: "한국어", lan:"ko"},{name: "العربية", lan:"ar"},{ name: "Deutsch", lan:"de"},{ name: "français", lan:"fr"},{ name: "русский", lan:"ru"}],
    header: {
      story: "Geschichten",
      language: "Deutsch/de",
      kit: "Pressemappe",
      storyHref:"https://fastclip.zhubai.love/",
      kitHref:"https://fastclip.app/presskit/PressKit.zip"
    },
    content: {
      title: "Ausschnitte auf völlig neue Weise bearbeiten",
      desc: "Ausschnitte sammeln, analysieren, einfügen und teilen",
      appStore: "FastClip 3 on the App Store",
      scoreStar: "5",
      score: "4.8",
      openLink: "Sprunglink",
      members: "2155 members",
      group: "Telegram beitreten",
      appDesc: {
        name: "Sie können Ausschnitte durch Zwischenablage des Systems, Erweiterung der Tastatur, Teilen der Erweiterung und Erweiterung des Browsers erhalten.",
        clipboard: "クリップボード",
        keyboard: "キーボード",
        pro: "[Pro]",
        share: "共有",
        safari: "Safari"
      },
      appNote:{
        name: 'Sie können Ausschnitte über Sync-Netzwerk, iCloud synchronisieren.',
        icloud: "iCloud",
        pro: "[Pro]",
        network: "Sync network"
      }
    },
    pricing:{
      title:"Preisgestaltung",
      options: [
        { package: "Nach Monat abonnieren", price: "0,49 €" },
        { package: "Nach Jahr abonnieren", price: "2,99 €" },
        { package: "Dauerhaft", price: "8,99 €" }
      ]
    },
    features: {
      title: "Funktion",
      details: [
        { type: "header", item: "Position", free: "Free", pro: "Pro" },
        { type: "text", item: "Snippets", free: "1000", pro: "Unlimited", border: "bottom" },
        { type: "image", item: "Keyboard extension(ios)", free: "0", pro: "1", border: "bottom" },
        { type: "image", item: "Safari extension(ios)", free: "1", pro: "1", border: "bottom" },
        { type: "image", item: "iCloud sync", free: "0", pro: "1", border: "none" },
        { type: "image", item: "File manage", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Sync network", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Handoff", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Cards", free: "1", pro: "1", border: "none" },
      ]
    }
  },
  fr: {
    key: "fr",
    languages: [{ name: "English", lan: "en" }, { name: "简体中文", lan:"zh"},{ name: "日本語", lan:"ja"},{ name: "한국어", lan:"ko"},{name: "العربية", lan:"ar"},{ name: "Deutsch", lan:"de"},{ name: "français", lan:"fr"},{ name: "русский", lan:"ru"}],
    header: {
      story: "Récit",
      language: "français/fr",
      kit: "Presskit",
      storyHref:"https://fastclip.zhubai.love/",
      kitHref:"https://fastclip.app/presskit/PressKit.zip"
    },
    content: {
      title: "Traiter les snippets de toute nouvelle façon",
      desc: "Recueillir les snippets, analyser, coller et partager",
      appStore: "FastClip 3 on the App Store",
      scoreStar: "5",
      score: "4.8",
      openLink: "auter le lien",
      members: "2155 members",
      group: "Joindre au Telegram",
      appDesc: {
        name: "Vous pouvez obtenir des snippets via le presse-papiers du système, l'extension de clavier, de partage et de navigateur.",
        clipboard: "クリップボード",
        keyboard: "キーボード",
        pro: "[Pro]",
        share: "共有",
        safari: "Safari"
      },
      appNote:{
        name: 'Vous pouvez utiliser le réseau Sync, iCloud pour synchroniser les snippets.',
        icloud: "iCloud",
        pro: "[Pro]",
        network: "Sync network"
      }
    },
    pricing:{
      title:"Tarification",
      options: [
        { package: "Abonnement mensuel", price: "0,49 €" },
        { package: "Abonnement annuel", price: "2,99 €" },
        { package: "Permanent", price: "8,99 €" }
      ]
    },
    features: {
      title: "Caractéristiques",
      details: [
        { type: "header", item: "Article", free: "Free", pro: "Pro" },
        { type: "text", item: "Snippets", free: "1000", pro: "Unlimited", border: "bottom" },
        { type: "image", item: "Keyboard extension(ios)", free: "0", pro: "1", border: "bottom" },
        { type: "image", item: "Safari extension(ios)", free: "1", pro: "1", border: "bottom" },
        { type: "image", item: "iCloud sync", free: "0", pro: "1", border: "none" },
        { type: "image", item: "File manage", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Sync network", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Handoff", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Cards", free: "1", pro: "1", border: "none" },
      ]
    }
  },
  ru: {
    key: "ru",
    languages: [{ name: "English", lan: "en" }, { name: "简体中文", lan:"zh"},{ name: "日本語", lan:"ja"},{ name: "한국어", lan:"ko"},{name: "العربية", lan:"ar"},{ name: "Deutsch", lan:"de"},{ name: "français", lan:"fr"},{ name: "русский", lan:"ru"}],
    header: {
      story: "История",
      language: "русский/ru",
      kit: "Данные СМИ",
      storyHref:"https://fastclip.zhubai.love/",
      kitHref:"https://fastclip.app/presskit/PressKit.zip"
    },
    content: {
      title: "Обработка сниппетов по-новому",
      desc: "Собирать сниппеты, анализировать, вставить и поделиться",
      appStore: "FastClip 3 on the App Store",
      scoreStar: "5",
      score: "4.8",
      openLink: "Перейти по ссылке",
      members: "2155 members",
      group: "Присоединиться к Telegram",
      appDesc: {
        name: "Вы можете получить сниппеты путем расширения буфера обмены, клавиатуры системы, расширения Share, расширения браузера.",
        clipboard: "クリップボード",
        keyboard: "キーボード",
        pro: "[Pro]",
        share: "共有",
        safari: "Safari"
      },
      appNote:{
        name: 'Вы можете осуществлять синхронизацию сниппетов через Sync network, iCloud.',
        icloud: "iCloud",
        pro: "[Pro]",
        network: "Sync network"
      }
    },
    pricing:{
      title:"Цены",
      options: [
        { package: "Подписка помесячно", price: "29 ₽" },
        { package: "Подписка по годам", price: "329 ₽" },
        { package: "Вечно", price: "849 ₽" }
      ]
    },
    features: {
      title: "Функция",
      details: [
        { type: "header", item: "Позиция", free: "Free", pro: "Pro" },
        { type: "text", item: "Snippets", free: "1000", pro: "Unlimited", border: "bottom" },
        { type: "image", item: "Keyboard extension(ios)", free: "0", pro: "1", border: "bottom" },
        { type: "image", item: "Safari extension(ios)", free: "1", pro: "1", border: "bottom" },
        { type: "image", item: "iCloud sync", free: "0", pro: "1", border: "none" },
        { type: "image", item: "File manage", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Sync network", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Handoff", free: "1", pro: "1", border: "none" },
        { type: "image", item: "Cards", free: "1", pro: "1", border: "none" },
      ]
    }
  },
};
